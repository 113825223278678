import asyncComponent from 'Demora/AsyncComponent';

const GetStarted = asyncComponent(() =>
	import(
		/* webpackChunkName: "specialinvite" */ '../../js/components/GetStarted'
	)
);

const AuthHome = asyncComponent(() =>
	import(/* webpackChunkName: "authhome" */ '../components/AuthHome')
);

export default [
	{
		path: '/getStarted/:gs',
		component: GetStarted,
	},

	{
		path: '/home/:tab',
		component: AuthHome,
	},
	{
		path: '/home/:tab/:tab1',
		component: AuthHome,
	},
];
