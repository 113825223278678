let React = require('react');

let config = {
	/*

		Add tabs to settings page

		[

			{
				title:'Tab title',
				url:'url', "/settings/**URL**"
				component: (<Component />),
			},

		];

	*/

	title: 'VisitorBuilder - Free website traffic',

	settingsTabs: [],

	addons: [],

	dataDomain:
		typeof document === 'undefined'
			? ''
			: document.location.port == 8080
			? 'http://visitorbuilder.test:8000'
			: '',

	graphQL: false,
};

export default config;
