const languages = (state = [], action) => {
	switch (action.type) {
		case 'SAVE_LANGUAGES':
			state = Object.keys(action.languages).map((code) => {
				const d = action.languages[code];

				return {
					value: code,
					label: d.language + ' (' + code.toUpperCase() + ') ',
				};
			});

			break;
	}

	return JSON.parse(JSON.stringify(state));
};

const countries = (state = [], action) => {
	switch (action.type) {
		case 'SAVE_COUNTRIES':
			state = Object.keys(action.countries).map((code) => {
				const d = action.countries[code];

				return {
					value: code,
					label:
						d.country +
						' (' +
						code +
						') ' +
						(d.credits == 0
							? ''
							: '(' +
							  d.credits +
							  ' extra credit' +
							  (d.credits > 1 ? 's' : '') +
							  ')'),
				};
			});

			break;
	}

	return JSON.parse(JSON.stringify(state));
};

export default {
	countries,
	languages,
};
